import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Container, Offcanvas, Nav, Navbar, NavDropdown, Image, Row, Col, Badge } from 'react-bootstrap';
import { LanguageProvider } from '../../../core/language-provider';
import { LoginManager } from '../../../core/login-manager';
import avatars from '../../../core/avatar';
import logo from '../../../assets/logo.svg';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigation } from 'swiper/modules';
import { DataManager } from '../../../core/data-manager';
import UseCartContext from '../../../contexts/CartContext.tsx';


export default function HeaderBlock() {

    const [userInfo, setUserInfo] = useState(null);
    const [dropVisible, setDropVisible] = useState(false);
    const [modal, setModal] = useState(false);
    const { cartItem , setCartItem } = UseCartContext();


    let location = useLocation();

    useEffect(() => {
        var element = document.getElementById("app-container");
        element.setAttribute("data-page", location.pathname);
    }, [location]);


    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);

        var headerOffcanvas = document.getElementById("responsive-header-navbar");
        if (headerOffcanvas.classList.contains('show')) {
            console.log('closing menu')
            var closeBtn = document.querySelector("#responsive-header-navbar .offcanvas-header .btn-close");
            closeBtn.click()
        }


    }, [location.pathname]);


    useEffect(() => {
        async function init() {
            var u = LoginManager.getUserInfo()
            setUserInfo(u)
        }
        init()
    }, []);

    console.log({'UseCartContext().cartItem' : cartItem});

    return (
        <>
            <div style={{ width: '100%', height: '30px', textAlign: 'center', backgroundColor: '#f10000', color: 'white' }}>
                <p style={{ fontSize: '15px', paddingTop: '4px' }}>Servizio clienti: Lun - Ven dalle 8:30 alle 18:00 telefonando allo 0431 50552</p>
            </div>
            <Navbar expand="lg" collapseOnSelect sticky="top" id="header-navbar" className='bg-white text-secondary py-4 px-3  border-bottom'>
                <Container fluid={'md'} >
                    <Navbar.Brand>
                        <Link to='/'>
                            <Image src={logo} height={30} />
                        </Link>

                    </Navbar.Brand>
                    <Nav.Link as={Link} to='/cart' className='d-block d-md-none'>
                        <FontAwesomeIcon className='ms-1' icon={'cart-shopping'} size={'lg'}>

                        </FontAwesomeIcon>
                        <Badge pill bg="danger">
                            {cartItem}
                        </Badge>
                    </Nav.Link>
                    <Navbar.Toggle aria-controls="responsive-header-navbar" />

                    <Navbar.Offcanvas id="responsive-header-navbar" placement="end" className='text-secondary'>
                        <Offcanvas.Header closeButton>
                            {<Offcanvas.Title>
                                Ciao, {userInfo && userInfo.name}
                            </Offcanvas.Title>}
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="me-auto" id="header-menu">
                                <Nav.Link as={Link} className='d-block d-md-none' to="/home-living">Home&Living</Nav.Link>
                                <NavDropdown title="Home&Living" autoClose='outside' className='d-none d-md-block' onClick={() => setModal(true)}>
                                    {
                                        modal === true ?
                                        <div className='d-flex flex-column justify-content-around p-3 gap-5 flex-lg-row' style={{ width: '600px' }}>
                                            <div className='flex flex-col gap-3'>
                                                <p>Spring Summer 2025</p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/17' style={{ textDecoration: 'none', color: 'black' }} >Annamaria</Link></p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/18' style={{ textDecoration: 'none', color: 'black' }} >Yachting Club</Link></p>
                                            </div>
                                            <div className='flex flex-col gap-3'>
                                                <p>Fuori Collezione</p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/19' style={{ textDecoration: 'none', color: 'black' }} >Annamaria</Link></p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/20' style={{ textDecoration: 'none', color: 'black' }} >Yachting Club</Link></p>
                                            </div>
                                        </div>
                                        : null
                                    }

                                </NavDropdown>

                                <Nav.Link as={Link} className='d-block d-md-none' to="/collezioni">Fashion</Nav.Link>
                                <NavDropdown title="Fashion" autoClose='outside' className='d-none d-md-block' onClick={() => setModal(true)}>
                                    {
                                        modal === true ?
                                        <div className='d-flex flex-column justify-content-around p-3 gap-5 flex-lg-row' style={{ width: '600px' }}>
                                            {
                                                userInfo.level === 2 ?
                                                <><div className='flex flex-col gap-3'>
                                                    <p>Raccolta ordini S/S 2025</p>
                                                    <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/11' style={{ textDecoration: 'none', color: 'black' }} >Annamaria</Link></p>
                                                    <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/12' style={{ textDecoration: 'none', color: 'black' }} >Amamitoo</Link></p>
                                                </div></>
                                                : null
                                            }

                                            <div className='flex flex-col gap-3'>
                                                <p>Nuova Collezione 2025</p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/4' style={{ textDecoration: 'none', color: 'black' }} >Annamaria</Link></p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/3' style={{ textDecoration: 'none', color: 'black' }} >Amamitoo</Link></p>
                                            </div>
                                            <div className='flex flex-col gap-3'>
                                                <p>Fuori Collezione</p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/7' style={{ textDecoration: 'none', color: 'black' }} >Annamaria</Link></p>
                                                <p className='fw-light text-uppercase' onClick={() => setModal(false)}><Link to='collezione/6' style={{ textDecoration: 'none', color: 'black' }} >Amamitoo</Link></p>
                                            </div>
                                        </div>
                                        : null
                                    }

                                </NavDropdown>
                                <Nav.Link as={Link} className={location && location.pathname === '/contattaci' && 'text-danger'} to="/contattaci">
                                    Contattaci
                                </Nav.Link>
                                
                            </Nav>
                            <Nav>
                                <Nav.Link as={Link} style={{height:'fit-content'}} className={`d-flex align-items-center my-auto ${ (location && location.pathname === '/cerca') && 'text-danger'}`}  to="/cerca">
                                    <FontAwesomeIcon className='ms-1' icon={'magnifying-glass'} size={'lg'}>search</FontAwesomeIcon>
                                </Nav.Link>
                                <Nav.Link as={Link} to='/profile' className={location && location.pathname === '/profile' && 'text-danger'}>
                                    <FontAwesomeIcon className='ms-1' icon={'user'} size={'lg'}></FontAwesomeIcon>
                                </Nav.Link>
                                <Nav.Link as={Link} to='/cart' className='d-none d-lg-block'>
                                    <FontAwesomeIcon className='ms-1' icon={'cart-shopping'} size={'lg'}>

                                    </FontAwesomeIcon>
                                    <Badge pill bg="danger">
                                        {cartItem}
                                    </Badge>
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>

                </Container>
            </Navbar>
        </>
    );
};