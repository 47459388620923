import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb, Table, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import logo from '../../assets/test.svg';
import { settings } from '../../config/settings';
import { LoginManager } from '../../core/login-manager';
import { HeaderBlock } from '../../components/BodyElements/HeaderBlock/index';
import e from 'cors';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../components/Breadcrumbs';
import nodisp from '../../assets/nodisp.jpeg';
import UseCartContext, { UpdateCartItem } from '../../contexts/CartContext.tsx';



export default function Dettaglio() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState('');
    const { k } = useParams();
    const id = parseInt(k);
    const [userInfo, setUserInfo] = useState();
    const [searchParams] = useSearchParams(); 

    const url = settings.ckdPath;

    const [data, setData] = useState([]);
    const [dataImage, setDataImage] = useState([]);
    const [urlImage, setUrlImage] = useState('');
    const [model, setModel] = useState(null);
    const [fantasy, setFantasy] = useState(null);
    const [selectedFantasy, setSelectedFantasy] = useState(null);
    const [fantasy_name, setFantasyName] = useState(null);
    const [taglia, setTaglia] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [price, setPrice] = useState(null);
    const [priceTotale, setPriceTotale] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [codeVariant, setCodeVariant] = useState();
    const [path, setPath] = useState();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [idVariant, setIdVariant] = useState(null);
    const [maxQuantity, setMaxQuantity] = useState(null);


    const [variant, setVariant] = useState(false);
    const [dataVariant, setDataVariant] = useState([]);


    const [clickedItem, setClickedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const {cartItem , setCartItem } = UseCartContext();

    const handleClick = (index) => {
        if (clickedItem === index) {
            // Se l'elemento cliccato è lo stesso, deselezionalo
            setClickedItem(null);
        } else {
            // Altrimenti, seleziona il nuovo elemento cliccato
            setClickedItem(index);
        }
    };

    useEffect(() => {
        init();
        getUserInfo();
    }, []);


    /* useEffect(() => {
        if (taglia !== null && price !== null) {
            
                //addCart();
            
        }
    }, [taglia, price]) */


    function getUserInfo() {
        var u = LoginManager.getUserInfo()
        setUserInfo(u)
    }


    async function init() {
        var constraints = [
            {
                "fieldName": "idModel",
                "type": "value",
                "value": id
            }
        ]
        var sorting = {
            'field': 'codice',
            'order': 'asc'
        }
        setIsLoading(true);
        var response = await DataManager.searchItems('product', 1, 50, sorting, '', [], constraints)

        console.warn({'product' : response.body})
        if (response.success === 1 && response.body.length > 0) {
            setData(response.body);
            setIsLoading(false);
            setDescription(response.body[0].idModel.description);
            setTitle(response.body[0].idModel.name)
            if (response.body[0].idModel.photo) {
                setUrlImage( getPhotoUrl(response.body[0].idModel?.photo?.entity, response.body[0].idModel?.photo?.hash , response.body[0].idModel?.photo?.extension) );
                setDataImage(response.body[0].idModel.photoGallery);
            } else {
                setUrlImage(nodisp);
            }
        }else {
            var constraintsModel = [
                {
                    "fieldName": "id",
                    "type": "value",
                    "value": id
                }
            ]
            var sortingModel = {
                'field': 'id',
                'order': 'asc'
            }
            var responseModel = await DataManager.searchItems('model', 1, 100, sortingModel, '', [], constraintsModel);
            console.log({responseModel : responseModel});
            if (responseModel.success === 1) {
                setTitle(responseModel.body[0].name);
                setDescription(responseModel.body[0].description);
                console.log(responseModel.body[0].description)
                if (responseModel.body[0].photo) {
                    setUrlImage( getPhotoUrl( responseModel.body[0].photo?.entity , responseModel.body[0].photo?.hash , responseModel.body[0].photo?.extension) );
                    setDataImage(responseModel.body[0].photoGallery);
                } else {
                    setUrlImage(nodisp);
                }
            }
            toast.error('Nessun prodotto disponibile!', {theme: 'colored'});
        }
    }

    async function getVariant(idFantasy, index) {
        const section = document.getElementById('taglie_container');
        const sectionTop = section.offsetTop;

        window.scrollTo({
            top: sectionTop,
            behavior: "smooth"
        });
        handleClick(index)
        if (data[0].idModel.photo) {
            setPath(url + 'upload/' + data[0].idModel.photo.entity + '/' + data[0].idModel.photo.hash + '.' + data[0].idModel.photo.extension);
        } else {
            setPath(nodisp)
        }

        var constraints = [
            {
                "fieldName": "idModel",
                "type": "value",
                "value": id
            },
            {
                "fieldName": "idFantasy",
                "type": "value",
                "value": idFantasy
            }
        ]
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var response = await DataManager.searchItems('product', 1, 30, sorting, '', [], constraints)
        console.warn(response);
        const data1 = response.body;
        if (response.success === 1) {
            var constraintsVariant = [
                {
                    "fieldName": "idProduct",
                    "type": "value",
                    "value": response.body[0].id
                }
            ]
            var sortingVariant = {
                'field': 'posizione',
                'order': 'asc'
            }
            var responseVariant = await DataManager.searchItems('variant', 1, 30, sortingVariant, '', [], constraintsVariant);
            console.log({'variant': responseVariant.body});
            setDataVariant(responseVariant.body);
            setVariant(true);
            setModel(data1[0].idModel.code);
            setFantasy(data1[0].idFantasy.codice);
            setFantasyName(data1[0].idFantasy.name);
        }
        else {
            setErrors('Errore. Riprova più tardi.')
        }
    }

    async function setDataCart() {
        if (quantity > maxQuantity) {
            toast.error('La quantità inserita supera quella disponibile', { theme: 'colored' });
        }
        else if (quantity === null) {
            toast.error('Inserire la quantità', { theme: 'colored' });
        }
        else {
            var constraints = [
                {
                    "fieldName": "codice",
                    "type": "value",
                    "value": codeVariant
                }
            ]
            var sorting = {
                'field': 'id',
                'order': 'desc'
            }
            var response = await DataManager.searchItems('variant', 1, 100, sorting, '', [], constraints);


            //console.log('RESPONSE',response);

            if (response.success === 1) {
                const dataCarrello = {
                    'idUser': { 'id': userInfo.id },
                    'model': model,
                    'fantasy': selectedFantasy.id,
                    'variant': response.body[0].taglia,
                    'code': codeVariant,
                    'quantity': parseFloat(quantity),
                    'listPrice': parseFloat(response.body[0].priceTotale),
                    'price': (parseFloat(response.body[0].priceTotale) * parseFloat(quantity)),
                    'image': getPhotoUrl(selectedFantasy.photoCover?.entity , selectedFantasy.photoCover?.hash , selectedFantasy.photoCover?.extension),
                    'discount': response.body[0].discount,
                    'variante': response.body[0].id,
                    'fantasy_name': fantasy_name
                }


                var constraintsExist = [
                    {
                        "fieldName": "variante",
                        "type": "value",
                        "value": response.body[0].id
                    },
                    {
                        "fieldName": "idUser",
                        "type": "value",
                        "value": userInfo.id
                    }
                ]
                var sortingExist = {
                    'field': 'id',
                    'order': 'desc'
                }




                var existResponse = await DataManager.searchItems('customer_cart', 1, 100, sortingExist, '', [], constraintsExist);
                if (existResponse.body.length === 0) {
                    var response = await DataManager.insertItem('customer_cart', dataCarrello);
                    if (response.success === 1) {
                        setCartItem(cartItem + parseInt(dataCarrello.quantity));
                        toast.success('Articolo aggiunto con successo', { theme: 'colored' });
                    }
                }
                else {
                    const existingQuantity = parseInt(existResponse.body[0].quantity);
                    let requestedQuantity = parseInt(dataCarrello.quantity);
                    const acceptedQuantity = maxQuantity - existingQuantity;
                    
                    console.log({ 'existingQuantity': existingQuantity, 'requestedQuantity': requestedQuantity, 'acceptedQuantity': acceptedQuantity });

                    // if cart has max quantity
                    if (existingQuantity === maxQuantity) {
                        toast.error('Quantità massima raggiunta. Prodotto non aggiunto al carrello', { theme: 'colored' });
                        return;
                    }
                    // if cart exceed max quantity
                    if (existingQuantity > maxQuantity) {
                        var dataUpdate = {
                            quantity: maxQuantity
                        }
                        var updateCart = await DataManager.updateItem('customer_cart', dataUpdate, existResponse.body[0].id);
                        toast.error('Prodotto non aggiunto al carrello. Quantità massima raggiunta', { theme: 'colored' });
                        window.location.reload();
                        return;
                    }

                    if (requestedQuantity > acceptedQuantity) {
                        requestedQuantity = maxQuantity - existingQuantity;
                        toast.info(`${acceptedQuantity} ${acceptedQuantity > 1 ? 'pezzo' : 'pezzi'} aggiunti.  Quantità massima raggiunta.`, { theme: 'colored' });
                    }
                    // continue cart requesting is lower than accepted quantity
                    var dataUpdate = {
                        quantity: requestedQuantity + existingQuantity
                    }
                    var updateCart = await DataManager.updateItem('customer_cart', dataUpdate, existResponse.body[0].id);
                    console.warn('update cart', updateCart);
                    if (updateCart.success === 1) {
                        setCartItem(requestedQuantity + existingQuantity);
                        toast.success('Quantità aggiornata con successo!', { theme: 'colored' });
                    } else {
                        toast.error('Prodotto non aggiunto al carrello, riprova più tardi', { theme: 'colored' });
                    }
                }
            }

        }
    }

    /* async function addCart() {


            const dataCarrello = {
                'idUser': { 'id': userInfo.id },
                'model': model,
                'fantasy': fantasy,
                'variant': taglia,
                'code': codeVariant,
                'quantity': parseFloat(quantity),
                'listPrice': price,
                'price': (price * parseFloat(quantity)),
                'image': path,
                'discount': discount,
                'variante': idVariant,
                'fantasy_name': fantasy_name
            }
            var response = await DataManager.insertItem('customer_cart', dataCarrello);
            console.warn(response, dataCarrello);
            if (response.success === 1) {
                //setSuccess('Articolo aggiunto al carrello!');
                //setErrors('');
                toast.success('Articolo aggiunto con successo', { theme: 'colored' });
                props.setCartItem(props.cartItem + 1);
            }

        

        

    } */

    async function getMax(e) {
        var constraints = [
            {
                "fieldName": "codice",
                "type": "value",
                "value": e
            }
        ]
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }

        var response = await DataManager.searchItems('variant', 1, 30, sorting, '', [], constraints);
        if (response.body[0].max_quantity_check === 1 || response.body[0].max_quantity_check === null) {
            setMaxQuantity(parseInt(response.body[0].quantity));
        } else {
            setMaxQuantity(2000000000);
        }

    }

    function doCodeVariant(e) {
        getMax(e);
        setCodeVariant(e);
    }

    function getPhotoUrl(entity, hash, ext) {
        if (!entity || !hash || !ext) {
            if (nodisp) {
                return nodisp;
            }
            return null;
        }
        
        const filePath = `${url}upload/${entity}/${hash}.${ext}`;
        return filePath;
    }

    let isFirstLoad = true;

    useEffect(() => {
        if(isFirstLoad) {
            const selectedElement = document.querySelector(`div[fantasycode="${searchParams.get('fantasy')}"]`);
            console.log({'selectedElement' : selectedElement});
            if (searchParams.get('fantasy') && selectedElement) {
                selectedElement.click();
            }
        }
    }, [data]);


    return (
        <>
            <Container className='d-flex align-items-center mt-3 mb-5'>
                <Breadcrumbs title={title}></Breadcrumbs>
            </Container>
            <Container className='my-4 py-4'>
                <div className='d-flex flex-md-row flex-column gap-5'>
                    <div className='col-md-3 col-12'>
                        <Image src={urlImage && urlImage} style={{ width: '100%' }}></Image>
                        <div className='d-flex flex-wrap mt-4 mb-5'>
                            {dataImage && dataImage.map(item => {
                                console.log({'item' : item});
                                return (
                                    <Image style={{ width: '30%', cursor: 'pointer' }}
                                        src={getPhotoUrl(item.file?.entity , item.file?.hash , item.file?.extension)}
                                        onClick={(e) => setUrlImage( e.target.src )}>
                                    </Image>
                                )
                            })}
                        </div>
                    </div>
                    <div className='col-12 col-md-9'>
                        <p>{description}</p>
                        {
                            (
                                () => {
                                    if (isLoading) {
                                        return (
                                            <div className='d-flex justify-content-center mt-5'>
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        if(data && data.length > 0){
                                            return (<>
                                                <h5 className='text-uppercase mt-5'><b>Fantasie disponibili</b></h5>
                                                <p>Seleziona una fantasia</p>
                                                <div className='row row-cols-md-5 row-cols-2'>
                                                    {(
                                                        () => {
                                                            return data.sort((a, b) => {
                                                                return parseInt(a.idFantasy?.codice) - parseInt(b.idFantasy?.codice);
                                                            }).map((item, index) => {
                                                                if (item.idFantasy) {
                                                                    return (
                                                                        <>
                                                                            <div key={item.id} className='col text-center'>
                                                                                <div className='p-1' 
                                                                                    id={item.idFantasy.id} 
                                                                                    key={index} 
                                                                                    fantasycode = {item.idFantasy.codice}
                                                                                    onClick={(e) => { 
                                                                                        getVariant(item.idFantasy.id, index);  
                                                                                        setSelectedFantasy(item.idFantasy)}
                                                                                        } 
                                                                                    style={{ cursor: 'pointer', border: clickedItem === index ? '1px solid red' : '0px', borderRadius: '10px' }}
                                                                                    >
                                                                                        <Image style={{ border: '1px grey solid', height: '120px', width: '120px' }} src={ getPhotoUrl(item.idFantasy?.photoCover?.entity  , item.idFantasy?.photoCover?.hash , item.idFantasy?.photoCover?.extension)} roundedCircle></Image>
                                                                                    <p className='mb-0'>{item.idFantasy?.name}</p>
                                                                                </div>
                    
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                    
                                                            });
                                                        }
                                                    )()}
                                                    { (
                                                        () => {
                                                            
                                                        }
                                                    )()}
                                                </div>


                                                <div id='taglie_container'>
                                                    {variant && <div id='taglie'>
                                                        <h5 className='text-uppercase mt-5 mb-2'><b>Misure disponibili</b></h5>
                                                        <div className='row row-cols-6 ms-1'>
                                                            <Table bordered striped style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Codice</th>
                                                                        <th>Misura</th>
                                                                        <th>Prezzo</th>
                                                                        <th>Quantità disponibile</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <React.Fragment >
                                                                        {dataVariant.map((variant, variantIndex) => (
                                                                            <tr key={variant.id}>
                                                                                <td>{variant.codice}</td>
                                                                                <td>{variant.taglia}</td>
                                                                                {variant.discount > 0 ? <td>
                                                                                    <p>{variant.priceList} € (-{variant.discount}%)</p>

                                                                                    <p><b>{parseFloat(variant.priceTotale).toFixed(2)} €</b></p></td>
                                                                                    :
                                                                                    <td><p>{variant.priceList} €</p></td>}
                                                                                <td>{variant.max_quantity_check === 0 ? "Nessun limite" : variant.quantity}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </React.Fragment>

                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        <div className='d-flex flex-md-row flex-column gap-3 mt-2 mb-5'>

                                                            <Form.Select style={{ width: 'auto' }} onChange={e => doCodeVariant(e.target.value)}>
                                                                <option>Seleziona misura</option>
                                                                {dataVariant.map(variant => (
                                                                    variant.quantity > 0 ? <option value={variant.codice} >{variant.taglia}</option> : null

                                                                ))}
                                                            </Form.Select>

                                                            <Form.Group controlId="formNome" style={{ width: '200px' }}>
                                                                <Form.Control type="number" min='1' max={maxQuantity} placeholder="Quantità" value={quantity ? quantity : 0} onChange={e => setQuantity(e.target.value)} />
                                                            </Form.Group>
                                                            <Button variant='danger' style={{ color: 'white' }} onClick={setDataCart}>Aggiungi al carrello</Button>
                                                        </div>


                                                    </div>
                                                    }
                                                </div>

                                            </>
                                            )
                                        }else{
                                            return (
                                                <h5 className='text-uppercase mt-5'><b>Nessun prodotto disponibile!</b></h5>
                                            )
                                        }
                                    }
                                }
                            )()
                        }


                    </div>
                </div>
            </Container>

            {errors.length > 0 &&
                <Alert variant='danger' style={{ position: 'absolute', top: '15%', right: '5%' }}>
                    {errors}
                </Alert>
            }
            {success.length > 0 &&
                <Alert variant='success' style={{ position: 'absolute', top: '15%', right: '5%' }}>
                    {success}
                </Alert>
            }



        </>
    )


};