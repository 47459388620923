import React, { createContext , useContext , useState } from "react";
import { DataManager } from "../core/data-manager";
import { LoginManager } from "../core/login-manager";

export const CartContext = createContext< {cartItem: number , setCartItem: React.Dispatch<React.SetStateAction<number>>} | undefined>(undefined);

export default function UseCartContext() {

    console.log({'inside a UseCartContext' : CartContext});
    const context = useContext(CartContext);

    if (context === undefined) {
        throw new Error('User is not logged or UseCartContext must be used within a CartContextProvider');
    }


    return context;
}

export function CartContextProvider({ children } : any) {
    const [cartItem, setCartItem] = useState(0);

    async function getNumberCart() {

        const userInfo = LoginManager.getUserInfo();

        if(!userInfo){
            throw new Error('User is not logged. Impossible fetching Cart');
        }

        var constraints = [
            {
                "fieldName": "idUser",
                "type": "value",
                "value": userInfo.id
            }
        ]
        
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var response = await DataManager.searchItems('customer_cart', 1, 30, sorting, '', [], constraints)
        //console.warn(response)
        if (response.success === 1) {
            let total = 0;
            response.body.forEach(item => {
                total += parseInt(item.quantity);
            });

            
        /* console.log({ 'cart': response.total });
        setCartItem(response.total); */
        
        //count total quantity
        setCartItem(total);
        }

    }

    getNumberCart();

    return (
        <CartContext.Provider value={{ cartItem, setCartItem }}>
            {children}
        </CartContext.Provider>
    );
}
