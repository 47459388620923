import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import { ApiManager } from '../../core/api-manager';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { toast } from 'react-toastify';
import logo from '../../assets/logo.svg'


export default function Registrati() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { p } = useParams();
    const page = parseInt(p)

    const [data, setData] = useState(null);
    const [totalPages, setTotalPages] = useState(0);

    const [name, setUserName] = useState('');
    const [surname, setUserSurname] = useState('');
    const [email, setUserEmail] = useState('');
    const [phone, setUserPhone] = useState('');
    const [password, setUserPassword] = useState('');


    const [nameLegale, setNameLegale] = useState('');
    const [surnameLegale, setSurnameLegale] = useState('');
    const [cfLegale, setCfLegale] = useState('');
    const [ragione, setRagione] = useState('');
    const [iva, setIva] = useState('');
    const [sdi, setSdi] = useState('');
    const [tipo, setTipo] = useState('');
    const [settore, setSettore] = useState('');


    const [visible, setVisible] = useState(true);





    useEffect(() => {
    }, [page]);






    async function handleRegister() {

        if (ragione !== '' && iva !== '' && sdi !== '' && tipo !== '' &&  settore !== '' && nameLegale !== '' && surnameLegale !== '' && cfLegale !== '') {

            const body = {
                name: name,
                surname: surname,
                email: email,
                password: password,
                phone: phone,
                ragione_sociale: ragione,
                vat_number: iva,
                sdi: sdi,
                tipo: tipo,
                settore: settore,
                nome_legale: nameLegale,
                cognome_legale: surnameLegale,
                cf_legale: cfLegale

            }
            console.log(body);

            const response = await ApiManager.sendRequest('/public/do_register', body);
            console.log(response);
            if (response.success === 1) {
                toast.success('Registrazione avvenuta con successo!', { theme: 'colored' })
                navigate('/');
                const data = {
                    name: name,
                    surname: surname,
                    emailTo: email
                }
                const responseEmailRegister = await ApiManager.sendRequest('/public/send_register', data);
                console.log(responseEmailRegister);
            }
            else if (response.success === 0 && response.message === 'user.exist') {
                toast.error("È già presente un'utente con questa mail", { theme: 'colored' })
            }else if (response.success === 0 && response.message === 'user.exist.is_deleted') {
                toast.error("E-mail già associata a un account eliminato. Contatta l'assistenza per ulteriori istruzioni.", { theme: 'colored' })
            }else {
                toast.error('Errore nella registrazione', { theme: 'colored' })
            }
        } else {
            toast.error('Compilare tutti i campi per procedere', { theme: 'colored' });
        }

    }

    function login() {
        navigate('/');
    }

    function goToPage2() {
        if (name !== '' && surname !== '' && email !== '' && phone !== '' && password !== '') {
            setVisible(false);
        }
        else {
            toast.error('Compilare tutti i campi per procedere', { theme: 'colored' });
        }
    }



    return (
        <>
            <Container fluid='md' className='mt-5 mb-5 '>
                <Row>
                    <Col md="4"></Col>
                    <Col md="4" className='align-items-center'>
                        <div className='d-flex flex-row justify-content-center w-100 mb-5'>
                            <Image src={logo} className='d-none d-md-block' style={{ width: '500px' }}></Image>
                            <Image src={logo} className='d-block d-md-none' style={{ width: '250px' }}></Image>
                        </div>
                        <h3 className="mb-4 mt-3 text-center text-uppercase">{LanguageProvider.get('forms.registrati')}</h3>

                        {visible ? <div>
                            <Form >
                                <Form.Group className="mb-3" controlId="formNome">
                                    <Form.Control type="text" placeholder="Nome" onChange={e => setUserName(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCognome">
                                    <Form.Control type="text" placeholder="Cognome" onChange={e => setUserSurname(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Control type="email" placeholder="Email" onChange={e => setUserEmail(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formTelefono">
                                    <Form.Control type="text" placeholder="Telefono" onChange={e => setUserPhone(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Control type="password" placeholder="Password" onChange={e => setUserPassword(e.target.value)} required />
                                </Form.Group>
                                <Button variant="danger" type='submit' className='w-100' style={{ color: 'white' }} onClick={() => { goToPage2() }}>
                                    Avanti
                                </Button>
                                <div className="text-center mt-3">
                                    <Link to="/" className='text-black'>Indietro</Link>
                                </div>
                            </Form>
                        </div> :
                            <Form >
                                <p className='text-center'>Dati Società</p>
                                <Form.Group className="mb-3" controlId="formIVA">
                                    <Form.Control type="text" placeholder="Ragione sociale" onChange={e => setRagione(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formIVA">
                                    <Form.Control type="text" placeholder="Partita IVA" onChange={e => setIva(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formSDI">
                                    <Form.Control type="text" placeholder="Codice SDI" onChange={e => setSdi(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Select aria-label="Default select example" onChange={e => setTipo(e.target.value)} required>
                                        <option>Tipo di società</option>
                                        <option value="SRL">SRL</option>
                                        <option value="SNC">SNC</option>
                                        <option value="SAS">SAS</option>
                                        <option value="Ditta personale">Ditta personale</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className='mb-3'>
                                    <Form.Select aria-label="Default select example" onChange={e => setSettore(e.target.value)} required>
                                        <option>Settore economico</option>
                                        <option value="Intimo-mare">Intimo-mare</option>
                                        <option value="Abbigliamento">Abbigliamento</option>
                                        <option value="Biancheria casa">Biancheria casa</option>
                                        <option value="Casalinghi">Casalinghi</option>
                                        <option value="Lista nozze">Lista nozze</option>
                                        <option value="Souvernir">Souvernir</option>
                                        <option value="Concept store">Concept store</option>
                                    </Form.Select>
                                </Form.Group>

                                <hr></hr>
                                <p className='text-center'>Dati Legale rappresentante</p>
                                <Form.Group className="mb-3" controlId="formNome">
                                    <Form.Control type="text" placeholder="Nome rappresentante" onChange={e => setNameLegale(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCognome">
                                    <Form.Control type="text" placeholder="Cognome rappresentante" onChange={e => setSurnameLegale(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCF">
                                    <Form.Control type="text" placeholder="CF rappresentante" onChange={e => setCfLegale(e.target.value)} required />
                                </Form.Group>
                                <Button variant="danger" className='w-100' style={{ color: 'white' }} onClick={() => { handleRegister() }} >
                                    Registrati
                                </Button>
                                <div className="text-center mt-3">
                                    <Link onClick={() => setVisible(true)} className='text-black'>Indietro</Link>
                                </div>
                            </Form>}
                    </Col>
                    <Col md="4"></Col>
                </Row>



            </Container>
        </>
    )


};