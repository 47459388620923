import React, { useState, useEffect , useContext } from "react";
import { Link } from "react-router-dom";
import { DataManager } from "../../core/data-manager";
import { settings } from "../../config/settings";
import { LoginManager } from "../../core/login-manager";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Button, Container, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import nodisp from '../../assets/nodisp.jpeg';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';
import { useQuery } from '@tanstack/react-query';
import useDebounce from "../../utilies/useDebounce.js";
import { getPhotoUrl } from "../../utilies/getPhotoUrl";
import { AddToCart, AddToCartProps } from "../../utilies/addToCart.tsx";
import UseCartContext from "../../contexts/CartContext.tsx";
import CustomPagination from "../../components/CustomPagination.tsx";


const title = "Ricerca Articoli";

function ProductItem({item}){

    const url = settings.ckdPath;
    return (
        <Link to={`/dettaglio/${item.idModel}?fantasy=${item.fantasyCode}`} className="text-decoration-none mb-4">
            <div className='flex flex-col flex-nowrap align-items-start justify-content-center gap-0' key={item.id}>
                <div className="position-relative">
                    {item && item.modelPhoto && item.modelPhoto?.entity ?
                        <Image src={ `${getPhotoUrl(item.modelPhoto.entity, item.modelPhoto.hash, item.modelPhoto.extension)}`} style={{ width: '100%' , height: 'fit-content' , minHeight: '200px' , objectFit: 'cover' }} className='mb-1 rounded-2' />
                        :
                        <Image src={nodisp} style={{ width: '100%', maxHeight: '300px' }} className="mb-1 rounded-2"></Image>
                    }
                    {item && item.fantasyPhoto && item.fantasyPhoto?.entity ?
                        <Image src={ `${getPhotoUrl(item.fantasyPhoto.entity, item.fantasyPhoto.hash, item.fantasyPhoto.extension)}` } style={{ width: '20%' , objectFit: 'cover' , right: '2%' , bottom: '2%' }} className='mb-1 border border-2 border-white ratio ratio-1x1 position-absolute rounded-circle' />
                        :
                        <Image src={'nodisp'} style={{ width: '20%' , objectFit: 'cover' , right: '2%' , bottom: '2%' }} className="d-none mb-1 rounded-2"></Image>
                    }
                </div>
                <p className="text-decoration-none text-center mt-2 mb-0 p-0"><small>{item.productCode}</small></p>
                <h6 className="text-decoration-none text-center mt-1 mb-0 p-0">{item.modelName}</h6>
            </div>
        </Link>
    )
}

function TableRowItem({item}){
    const {cartItem , setCartItem} = UseCartContext();

    console.log(item);
    const handleKeyDown = (e : any) => {
        const ref = e.currentTarget;
        const value = parseInt(ref.value, 10) || 1;
        const max = parseInt(ref.getAttribute('max') || '9999999999', 10);
        console.log('value', value, 'max', max)
        if (value) {
          const newValue = value > max ? max.toString() : value.toString();
          ref.value = newValue;
        }
      };

    async function handleAddToCart(e : any , item : any) {
        e.preventDefault();
        const addToCartData : AddToCartProps = {
            'idVariant': item.idVariant,
            'variantSize': item.variantSize,
            'variantCode': item.variantCode,
            'userQuantity': parseInt((document.getElementById(`quntity-variant_${item.idVariant}`) as HTMLInputElement).value),
            'maxQuantity': item.max_quantity_check === 0 ? 9999999999 : item.quantity,
            'listPrice': item.priceList,
            'price': item.priceTotale,
            'imageUrl': getPhotoUrl(item.modelPhoto.entity, item.modelPhoto.hash, item.modelPhoto.extension) || '',
            'discount': item.discount,
            'fantasyName': item.fantasyName,
            'idModel': item.idModel,
            'idFantasy': item.idFantasy
        };
        AddToCart(addToCartData).then((response) => {
            if(response && response > 0){
                setCartItem(cartItem + response);
            }
            if(response){
                const ref = document.getElementById(`quntity-variant_${item.idVariant}`) as HTMLInputElement;
                ref.value = '1';
            }
        });

    }

    return (
            <tr key={item.idVariant}>
                <td><Image src={`${getPhotoUrl(item.modelPhoto.entity, item.modelPhoto.hash, item.modelPhoto.extension)}`} alt={item.modelName} style={{ width: '50px' , height: '50px' , objectFit: 'cover' }} className='mb-1 rounded-2' /></td>
                <td>{item.variantCode}</td>
                <td>{item.variantSize}</td>
                {item.discount > 0 ? <td>
                    <p>{item.priceList} € (-{item.discount}%)</p>

                    <p><b>{parseFloat(item.priceTotale).toFixed(2)} €</b></p></td>
                    :
                    <td><p>{item.priceList} €</p></td>}
                <td>{item.max_quantity_check === 0 ? "Nessun limite" : item.quantity}</td>
                <td style={{ position: '-webkit-sticky', position: 'sticky', right: 0 , boxShadow: '1px 0 0 #aaa inset', }}>
                    <div className="d-flex justify-content-center align-items-stretch gap-2">
                        <Form.Control 
                            type="number" 
                            min="1" 
                            id={`quntity-variant_${item.idVariant}`}
                            max={item.max_quantity_check === 0 ? 9999999999 : item.quantity} 
                            defaultValue="1" 
                            className="w-auto mx-2"
                            style={{ maxWidth: '4rem' , minWidth: '2rem' }} 
                            onKeyUp={handleKeyDown}
                        />
                        <Button className="btn btn-danger text-white"
                            onClick={ e => {handleAddToCart(e , item)} }
                        >Aggiungi</Button>
                        <Link to={`/dettaglio/${item.idModel}?fantasy=${item.fantasyCode}`} className="btn btn-outline-primary border-danger text-danger">
                            <FontAwesomeIcon icon={'eye'} size="sm"/>
                        </Link>
                    </div>
                </td>
            </tr>
    )
}



function Loading({isVisibile}){
    useEffect(() => {
        const ref = document.getElementById('loadingContainer');
        if(isVisibile){
                if(ref) ref.style.zIndex = '3';
        }else{
            setTimeout(() => {
                if(ref) ref.style.zIndex = '0';
            }, 300)
        }
        
        const loadingContainer = document.getElementById('loadingContainer');
        const loadingContainerHeight = !loadingContainer ? window.innerHeight : window.innerHeight - loadingContainer.getBoundingClientRect().top;

        if (loadingContainer) {
            const loadingDiv = loadingContainer.querySelector('div');
            if (loadingDiv) {
                loadingDiv.style.height = `${loadingContainerHeight}px`;
            }
        }
    })

    return (
        <div id="loadingContainer" className={`position-absolute w-100 h-100 top-0 left-0 d-flex justify-content-center}`} style={{ zIndex: '3' , opacity: isVisibile ? 1 : 0 , transition: 'all 0.3s 0s ease-in-out' , backgroundColor: 'rgba(255,255,255,0.5)'}}>
            <div className="sticky-top d-flex flex-wrap flex-col align-items-center justify-content-center w-100" style={{ maxHeight: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}

function EmptyResults(){
    return (
        <div className="d-flex justify-content-center text-danger">
            <h3>Non ci sono risultati per la tua ricerca</h3>
        </div>
    )
}



export default function Search() {

    const [linesFilter, setLinesFilter] = useState([{ 'Tutte le linee': '' }, { 'Home & Living': 'Home & Living' }, { 'Fashion': 'Fashion' }]);
    const [pageData, setPageData] = useState({
        first_page: 1,
        prev_page: 1,
        current_page: 1,
        next_page: 1,
        last_page: 1
    });

    //form states
    const [searchText, setSearchText] = useState('');
    const [linea, setLinea] = useState('');
    const [page, setPage] = useState(1);

    // Debounce only the text input
    const debouncedSearchText = useDebounce(searchText, 500); // use debouce state to avoid too many requests

    const [lastFetchResults , setLastFetchResults] = useState('IS_FIRST_LOAD' as string | any[] | null);

    const searchResults = useQuery({
        queryKey: ['search' , debouncedSearchText, linea, page],
        queryFn: async () => {
            return dataFetch();
        },
        staleTime : 1 * 60 * 60 * 1000,
    });

    // after the first render
    useEffect(() => {
        if(searchResults.data){
            const prev_page_number = page - 1 > 0 ? page - 1 : 1;
            const next_page_number = page + 1 > searchResults.data.pages ? searchResults.data.pages : page + 1;
            setPageData({
                first_page: 1,
                prev_page: prev_page_number,  
                current_page: page,
                next_page: next_page_number,
                last_page: searchResults.data.pages
            });
            setLastFetchResults(searchResults.data.body);
        }
    }, [searchResults.data])

    async function dataFetch(  ) {
        const sorting = {
            'field': 'id',
            'order': 'desc'
        };
        const constraints = [
            {
                fieldName : "lineName",
                type: 'like',
                value: linea
            },
        ];
        const response = await DataManager.searchItems('gyv_search_variant', page, 12, sorting , searchText, ['variantCode' , 'productCode' ,'modelCode' , 'fantasyCode' , 'modelName' , 'fantasyName'] , constraints);
        return response;
    }

    const handleRadioChange = (value: string | undefined) => {
        setLinea(value ?? ''); // if value is undefined or null, setLinea to empty string
        setPage(1); // Reset to page 1 every time user changes line
    };

    return (
        <>
            <Container className="d-flex align-items-center mt-3 mb-5">
                <Breadcrumbs title={title}></Breadcrumbs>
            </Container>
            <Container className="mt-5 pb-4 d-flex flex-column flex-md-row gap-4">
                <Col className="col-12 col-md-3">
                    <Form id="searchForm" >
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Cerca un articolo</Form.Label>
                            <Form.Control className={'rounded-pill px-3'} type="text" name="query" aria-label="Ricerca" placeholder={"Cerca codice"} value={searchText} onChange={ e => setSearchText(e.target?.value) } />
                        </Form.Group>
                        <hr/>
                        <div>
                            <h4 className="mt-3 d-flex flex-col flex-wrap align-items-start">Linee</h4>
                            {
                                linesFilter.map((obj, index) => {
                                    const title = Object.keys(obj)[0];
                                    const value = obj[title];
                                    const isChecked = linea === value ? true : false;
                                    return (
                                        <div key={index}>
                                            <Form.Check
                                                type="radio"
                                                id={`linea-${value}`}
                                                name="linea"
                                                value={value}
                                                className="d-none"
                                                defaultChecked={isChecked}
                                                onChange={() => handleRadioChange(value)}
                                            />
                                            <Form.Label htmlFor={`linea-${value}`}
                                                className={`px-4 py-2 w-100 text-center rounded-4 border border-1 ${isChecked ? 'border-danger' : 'border-secondary-emphasis'} }`}
                                            >
                                                {title}
                                            </Form.Label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Form>
                </Col>
                <Col className="col-12 col-md-9 position-relative mb-4" >
                    {
                        searchResults.isLoading ? <Loading isVisibile={true}/> : <Loading isVisibile={false}/>
                    }
                    { (
                        () => {
                                if (lastFetchResults && Array.isArray(lastFetchResults) && lastFetchResults.length > 0) {

                                    return(
                                        <>
                                        <div className="row row-cols-lg-4 row-cols-md-3 row-cols-2 z-1 overflow-auto position-relative" >
                                            <Table className="z-1" bordered striped style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Anteprima</th>
                                                        <th>Codice</th>
                                                        <th>Misura</th>
                                                        <th>Prezzo</th>
                                                        <th>Quantità disponibile</th>
                                                        <th style={{ position: '-webkit-sticky', position: 'sticky', right: 0 , boxShadow: '1px 0 0 #aaa inset', }}>Azioni</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        lastFetchResults.map((item, index) => { 
                                                            return <TableRowItem key={index} item={item} />
                                                        } )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        {
                                        <div>
                                            <CustomPagination pageData={pageData} setPage={setPage}  />
                                        </div>
                                        }
                                        </>
                                    ) 
                                }else if(lastFetchResults === 'IS_FIRST_LOAD'){
                                    
                                }else{
                                    return <EmptyResults />
                                }
                        }
                    )() }
                </Col>
            </Container>
        </>
    );
}

