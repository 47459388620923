import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form , Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import { settings } from '../../config/settings';
import Breadcrumbs from '../../components/Breadcrumbs';
import nodisp from '../../assets/nodisp.jpeg';
import logo from '../../assets/logo.svg';
import { getPhotoUrl } from '../../utilies/getPhotoUrl.js';
import { useQuery } from '@tanstack/react-query';
import CustomPagination from '../../components/CustomPagination.tsx';
import useDebounce from '../../utilies/useDebounce.js';

function ContainerItem({ data }) {
    return (
        <Container fluid='md' className='mt-5 mb-5'>
            <div className='container'>
                <div className='row row-cols-md-4 row-cols-2'>
                    {data.sort((a, b) => {
                        return parseInt(a.code) - parseInt(b.code);
                    }).map(item => {
                        console.log({item});
                        return ProductItem({item});
                    })}
                </div>

            </div>
        </Container>
    );
}

function ProductItem({item}){
    return (
        <Link to={`/dettaglio/${item.id}`} className="text-decoration-none mb-4 pb-4">
            <div className='flex flex-col flex-nowrap align-items-start justify-content-center gap-0' key={item.id}>
                <div className="position-relative">
                {item && item.photo && item.photo.entity ?
                    <Image src={ `${getPhotoUrl(item.photo.entity, item.photo.hash, item.photo.extension)}`} style={{ width: '100%' , height: 'fit-content' , minHeight: '200px' , objectFit: 'cover' }} className='mb-1 rounded-2' />
                    :
                    <Image src={nodisp} style={{ width: '100%', maxHeight: '300px' }}></Image>
                }
                </div>
                <h6 className="text-decoration-none text-center mt-1 mb-0 p-0">{item.name}</h6>
            </div>
        </Link>
    )
}

export function CardItem( item ) {

    const url = settings.ckdPath;

    return (
        <Link to={`/dettaglio/${item.id}`}>
            <div className='flex flex-col' key={item.id}>
                {item && item.photo && item.photo.entity ?
                    <Image src={url + 'upload/' + item.photo.entity + '/' + item.photo.hash + '.' + item.photo.extension} style={{ width: '100%' }} className='mb-1' />
                    :
                    <Image src={nodisp} style={{ width: '100%', maxHeight: '300px' }}></Image>}
            
                <p>{item.name}</p>
            </div>
        </Link>
    )
}

function ContainerNoData() {
    return (
        <div style={{ height: '70vh' }} className='w-100 d-flex flex-column align-items-center justify-content-center'>
            {/* <img src={logo} alt="Annamaria" className='w-25 d-block m-4'/> */}
            <h2 className='text-center p-10'>Nessun prodotto disponibile</h2>
            <Button as={Link} to='/' variant='danger' className='m-4' style={{ color: 'white' }}>Torna alla home</Button>
        </div>
    )
}

export default function Collection() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');

    const { k } = useParams();
    const id = parseInt(k);

    const [linea, setLinea] = useState(3);
    const [title, setTitle] = useState('');
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebounce(searchText, 500);
    const [lastFetchResults , setLastFetchResults] = useState(undefined);
    const [pageData , setPageData] = useState({
        first_page: 1,
        prev_page: 1,
        current_page: 1,
        next_page: 1,
        last_page: 1
    });

    async function fetch() {
        
        var constraints = [
            {
                "fieldName": "idLine",
                "type": "value",
                "value": id
            }
        ];
        var sorting = {
            'field': 'code',
            'order': 'asc'
        };
        
        DataManager.getItem('line', id)
        .then(response => {
            if (response.success !== 1) {
                throw new Error;
            }
            setTitle(response.body[0].name);
        })
        .catch(error => {
            setTitle('');
            console.error(error);
        });

        const query = debouncedSearchText;
        let fields = [];
        if(query){
            fields = ['code' , 'name'];
        }

        return await DataManager.searchItems('model', page, 300, sorting, query, fields, constraints);

    }

    const fetchData = useQuery({
        queryKey: ['collection', id , page, debouncedSearchText],
        queryFn: () => fetch(),
    })

    useEffect(() => {
        if(fetchData.data){
            const prev_page_number = page - 1 > 0 ? page - 1 : 1;
            const next_page_number = page + 1 > fetchData.data.pages ? fetchData.data.pages : page + 1;
            setPageData({
                first_page: 1,
                prev_page: prev_page_number,  
                current_page: page,
                next_page: next_page_number,
                last_page: fetchData.data.pages
            });
            setLastFetchResults(fetchData.data.body);
        }
    }, [fetchData.data]);

    

    return (
        <>
            <Container className='d-flex align-items-center mt-3 mb-5 flex-column flex-lg-row'>
                <Breadcrumbs title={title}></Breadcrumbs>
                <div className='rounded-pill d-flex align-items-center my-4 my-lg-0' style={{ width: '280px' }}>
                    <Form.Control className={'rounded-0 rounded-start-pill px-3'} type="text" name="query" aria-label="Ricerca" placeholder={"Cerca Prodotto"} value={searchText} onChange={ e => setSearchText(e.target?.value) } />
                    <Button className='rounded-0 rounded-end-pill btn btn-danger text-white' onClick={(e) => {e.preventDefault(); setSearchText(document.getElementById('query').value)}}><FontAwesomeIcon icon={'search'} size='sm'/></Button>
                </div>
            </Container>
            {
                !fetchData.isLoading ? 
                    (
                        lastFetchResults && lastFetchResults.length > 0 ?
                        <>
                            <ContainerItem key={id} data={lastFetchResults}></ContainerItem>
                            <Container className='d-flex align-items-center justify-content-end mt-3 mb-5'>
                                <CustomPagination pageData={pageData} setPage={setPage} />
                            </Container>
                            
                        </>

                        :
                        <ContainerNoData></ContainerNoData>
                    )
                    :
                    <div style={{ height: '70vh' }} className='w-100 d-flex flex-column align-items-center justify-content-center'>
                        <div className="spinner-border text-danger" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            }
        </>
    )


};