import { settings } from '../config/settings';
import nodisp from '../assets/nodisp.jpeg';

export function getPhotoUrl(entity , hash , ext){
    const url = settings.ckdPath;

    if (!entity || !hash || !ext) {
        if (nodisp) {
            return nodisp;
        }
        return null;
    }
    
    const filePath = `${url}upload/${entity}/${hash}.${ext}`;
    return filePath;
}
