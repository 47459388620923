import { toast } from 'react-toastify';
import { DataManager } from '../core/data-manager.js';
import { LoginManager } from '../core/login-manager.js';

export type AddToCartProps = {
    idModel: number;
    idFantasy: number;
    idVariant: number;
    variantSize: string;
    variantCode: string;
    userQuantity: number;
    maxQuantity: number;
    listPrice: number;
    price: number;
    imageUrl: string;
    discount: number;
    fantasyName: string;
}

  
export async function AddToCart({ idModel, idFantasy, idVariant, variantSize, variantCode, userQuantity, maxQuantity, listPrice, price, imageUrl, discount, fantasyName }: AddToCartProps) {
    const user = LoginManager.getUserInfo();

    const idUser = user.id;

    if (idUser && idUser <= 0) {
        toast.error('Devi essere loggato per aggiungere un prodotto al carrello', { theme: 'colored' });
        return;
    }

    console.log({
        'idUser': { 'id': idUser },
        'model': idModel,
        'fantasy': idFantasy,
        'variant': variantSize,
        'code': variantCode,
        'quantity': userQuantity,
        'listPrice': listPrice,
        'price': (price * userQuantity),
        'image': imageUrl,
        'discount': discount,
        'variante': idVariant,
        'fantasy_name': fantasyName
    });
    
    if (userQuantity > maxQuantity) {
        toast.error('La quantità inserita supera quella disponibile', { theme: 'colored' });
        return;
    }
    
    if (userQuantity === null) {
        toast.error('Inserire la quantità', { theme: 'colored' });
        return;
    }

    var constraints = [
        {
            "fieldName": "codice",
            "type": "value",
            "value": variantCode
        }
    ]
    var sorting = {
        'field': 'id',
        'order': 'desc'
    }
    var response = await DataManager.searchItems('variant', 1, 100, sorting, '', [], constraints);

    if (response.success === 1) {
        const dataCarrello = {
            'idUser': { 'id': idUser },
            'model': idModel,
            'fantasy': idFantasy,
            'variant': variantSize,
            'code': variantCode,
            'quantity': userQuantity,
            'listPrice': listPrice,
            'price': (price * userQuantity),
            'image': imageUrl,
            'discount': discount,
            'variante': idVariant,
            'fantasy_name': fantasyName
        }


        var constraintsExist = [
            {
                "fieldName": "variante",
                "type": "value",
                "value": idVariant
            },
            {
                "fieldName": "idUser",
                "type": "value",
                "value": idUser
            }
        ]
        var sortingExist = {
            'field': 'id',
            'order': 'desc'
        }




        var existResponse = await DataManager.searchItems('customer_cart', 1, 100, sortingExist, '', [], constraintsExist);
        if (existResponse.body.length === 0) {
            var response = await DataManager.insertItem('customer_cart', dataCarrello);
            if (response.success === 1) {
                toast.success('Articolo aggiunto con successo', { theme: 'colored' });
                return userQuantity;
            } else {
                toast.error('Impossibile aggiungere al carrello, riprova più tardi', { theme: 'colored' });
                return null;
            }
        } else {
            const existingQuantity = parseInt(existResponse.body[0].quantity);
            let requestedQuantity = dataCarrello.quantity;
            const acceptedQuantity = maxQuantity - existingQuantity;
            
            // if cart has max quantity
            if (existingQuantity === maxQuantity) {
                toast.error('Quantità massima raggiunta. Prodotto non aggiunto al carrello', { theme: 'colored' });
                return null;
            }
            // if cart exceed max quantity
            if (existingQuantity > maxQuantity) {
                var dataUpdate = {
                    quantity: maxQuantity
                }
                var updateCart = await DataManager.updateItem('customer_cart', dataUpdate, existResponse.body[0].id);
                toast.error('Prodotto non aggiunto al carrello. Quantità massima raggiunta', { theme: 'colored' });
                window.location.reload();
                return null;
            }

            if (requestedQuantity > acceptedQuantity) {
                requestedQuantity = maxQuantity - existingQuantity;
                toast.info(`${acceptedQuantity} ${acceptedQuantity > 1 ? 'pezzo' : 'pezzi'} aggiunti.  Quantità massima raggiunta.`, { theme: 'colored' });
                return 0;
            }
            // continue cart requesting is lower than accepted quantity
            var dataUpdate = {
                quantity: requestedQuantity + existingQuantity
            }
            var updateCart = await DataManager.updateItem('customer_cart', dataUpdate, existResponse.body[0].id);
            if (updateCart.success === 1) {
                toast.success('Quantità aggiornata con successo!', { theme: 'colored' });
                return requestedQuantity;
            } else {
                toast.error('Prodotto non aggiunto al carrello, riprova più tardi', { theme: 'colored' });
                return null;
            }
        }
    }

}