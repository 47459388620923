import React from 'react';
import { Pagination } from 'react-bootstrap';

export type PageData = {
    first_page: number,
    prev_page: number,
    current_page: number,
    next_page: number,
    last_page: number
}

export default function CustomPagination({ pageData , setPage } : { pageData : PageData , setPage : React.Dispatch<React.SetStateAction<number>> }) {
    const { first_page, prev_page, current_page, next_page, last_page } = pageData;
  
    return (
      <Pagination className="d-flex justify-content-end mt-4">
        {/* First Page */}
        <Pagination.First 
          disabled={current_page === first_page} 
          onClick={ () => setPage(first_page) }
        />
        
        {/* Previous Page (labeled "foward_page" in your data) */}
        <Pagination.Prev 
          disabled={current_page === first_page} 
          onClick={ () => setPage( prev_page )}
        />
  
        {/* Current Page (active) */}
        <Pagination.Item active>{current_page}</Pagination.Item>
        
        {/* Next Page */}
        <Pagination.Next 
          disabled={current_page === last_page} 
          onClick={ () => setPage(next_page) }
        />
        
        {/* Last Page */}
        <Pagination.Last 
          disabled={current_page === last_page} 
          onClick={ () => setPage(last_page) }
        />
      </Pagination>
    );
  }
